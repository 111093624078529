import moment from "moment";
import {read, utils} from 'xlsx';
import {Environment, GeneralAppInfo} from "./constants";
import {PermissionsEnum} from "./permissions.enum";

export const setKey = key => {
	return `${getEnvironment()}-${key}`
};

export const getYear = () => {
	const date = new Date();
	return date.getFullYear();
};

export const getDay = (value) => {
	let date = new Date();
	date.setDate(date.getDate() + value);
	return date;
};

export const getDayRange = (value) => {
	const startDate = moment(getDay(value)).startOf('date').endOf('hour').toISOString();
	const endDate = moment(getDay(value)).endOf('date').endOf('hour').toISOString();
	return {startDate, endDate}
};

export const getWeek = (difference) => {
	let date = new Date();
	date.setDate(date.getDate() - date.getDay() + difference);
	return date;
};

export const getWeekRange = (startValue) => {
	const startDate = moment(getWeek(startValue)).endOf('date').toISOString();
	const endDate = moment(getWeek(startValue + 7)).endOf('date').toISOString();
	return {startDate, endDate}
};

export const getMonthName = (value) => {
	const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	return month[value];
};

export const yearPicker = startRange => {
	const date = new Date();
	let array = [];
	while(startRange <= date.getFullYear()) {
		const newObject = {label: startRange, value: startRange.toString()}
		array.unshift(newObject);
		startRange++
	}
	return array;
};

export const copyToClipboard = async text => {
	return await navigator.clipboard.writeText(text);
}

export const getEnvironment = () => {
	let env;
	if(window.location.hostname === GeneralAppInfo.LOCALHOST) env = Environment.LOCAL;
	else if(window.location.hostname === GeneralAppInfo.BETA_BASEURL_SIJU) env = Environment.SANDBOX;
	else if(window.location.hostname === GeneralAppInfo.PROD_BASEURL_SIJU) env = Environment.PRODUCTION;
	return env;
};

export const formatNumber = value => {
	const numFormat = Intl.NumberFormat('en-US');
	numFormat.format(value);
	return numFormat.format(value);
};

export const maskString = str => {
	if(!str || str.length === 0) return "";
	const lastUnderscoreIndex = str.lastIndexOf('_');
	const prefix = str.substring(0, lastUnderscoreIndex + 1);
	const maskedString = str.substring(lastUnderscoreIndex + 1, str.length - 5).replace(/\w/g, "#");
	const suffix = str.substring(str.length - 5);
	return `${prefix}${maskedString}${suffix}`
}


export const formatSubscriptionPlan = payloadData => {
	let colourMaps = ['primary-gradient', 'warning-gradient', 'success-gradient', 'danger-gradient', 'info-gradient'];
	return payloadData.map((item, index) => ({
		...item,
		label: item['name'],
		fill: index > colourMaps.length ? colourMaps[Math.floor(Math.random() * 5)] : colourMaps[index],
	}));
};

export const colorMap = payloadData => {
	let colourMaps = ['primary', 'secondary', 'info', 'warning', 'dark', 'success', 'danger'];
	return payloadData.map((item, index) => ({
		...item,
		fill: index > colourMaps.length ? colourMaps[Math.floor(Math.random() * 7)] : colourMaps[index],
	}));
};

export const toTitleCase = str => {
	if(!str || str.length === 0) return "";
	str = (/[_&\\/#, +()$~%.'":*?<>{}-]/g).test(str) ? str.replaceAll(/[_&\\/#, +()$~%.'":*?<>{}-]/g, ' ') : str;
	return str.trim().split(' ').map(word => (
		word[0] ? word[0].toUpperCase() + word.substring(1).toLowerCase() : word + word.substring(1).toLowerCase()
	)).join(' ')
}

export const formatUser = payloadData => {
	return payloadData.map(item => ({
			...item,
			label: `${toTitleCase(item['first_name'])} ${toTitleCase(item['last_name'])}`,
			value: item.id,
		})
	);
};

export const formatCoupons = payloadData => {
	if(!payloadData || payloadData.length === 0) return [];
	else return payloadData.map(item => ({
		...item,
		label: `${item.code} - ${item.discount}%`,
		value: item.code
	}));
};

export const formatBusinesses = payloadData => {
	if(!payloadData || payloadData.length === 0) return [];

	else return payloadData.map(item => ({
		...item,
		label: item['name'],
		value: item['id'],
	}));
};

export const formatProducts = payloadData => {
	if(!payloadData || payloadData.length === 0) return [];

	else return payloadData.map(item => ({
		...item,
		label: item['name'],
		value: item['sku'],
	}));
};

export const formatOrganisation = payloadData => {
	if(!payloadData || payloadData.length === 0) return [];

	else return payloadData.map(item => ({
		...item,
		label: item['name'],
		value: item['reference'],
	}));
};

export const formatTripTypes = payloadData => {
	return payloadData.map(item => ({
			...item,
			label: !item.system_name.startsWith('DISTRIBUTION') ? `${item.customized_name} (${item.system_name} MODEL)` : item.customized_name ? item.customized_name : item.system_name,
			value: item.customized_name
		})
	);
};

export const formatSubOrganisationTripTypes = payloadData => {
	return payloadData.map(item => ({label: item.key, value: item.key}));
};

export const formatBookingTripType = payloadData => {
	const newArray = payloadData.map(item => ({
			label: item.customized_name ? toTitleCase(item.customized_name) : toTitleCase(item.system_name),
			value: item.system_name, filter: `&system_trip_type=${item.system_name}`
		})
	);
	return [{label: 'All Trips', value: "ALL", filter: ""}, ...newArray]
};

export const formatOrderSets = payloadData => {
	let formattedOrderSets = [];
	if(!payloadData || payloadData.length === 0) return formattedOrderSets;

	for(let item of payloadData) {
		if(item.status === "PREPARED") {
			const address = (item.merchant && item.merchant.address && (item.merchant.address.name || item.merchant.address.city || item.merchant.address.address)) ? (item.merchant.address.name || item.merchant.address.city || item.merchant.address.address) : "";
			const store = (item.merchant && item.merchant.extras && item.merchant.extras.name) ? `- ${item.merchant.extras.name} - ` : "";
			const reference = item.reference, added_on = item.created_at ?? Date.now();
			const label = `${reference} ${store} ${address} (${moment(added_on).format("lll")})`
			formattedOrderSets.push({...item, label: label ?? item.reference, value: item.reference});
		}
	}
	return formattedOrderSets;
};

export const formatTripTypeByCategory = (tripTypes, type) => {
	let businessCategory = [], customerCategory = [];
	for(let tripType of tripTypes) {
		if(tripType.system_name.startsWith('DISTRIBUTION') && type === 'BUSINESS') {
			const formatData = {
				...tripType,
				label: tripType.customized_name ? tripType.customized_name : tripType.system_name,
				value: tripType.customized_name
			}
			businessCategory.push(formatData);
		}
		if(!tripType.system_name.startsWith('DISTRIBUTION') && type === 'CUSTOMER') {
			const formatData = {
				...tripType,
				label: tripType.customized_name ? `${tripType.customized_name} (${tripType.system_name} MODEL)` : tripType.system_name,
				value: tripType.customized_name
			}
			customerCategory.push(formatData)
		}
	}
	if(type === 'BUSINESS') return businessCategory;
	else return customerCategory;
};

export const formatDynamicAuthOptions = payloadData => {
	let dynamicField = payloadData.map(item => ({...item, label: toTitleCase(item.key), value: item.key}));
	return [{label: 'Email', value: 'email'}, ...dynamicField];
};

export const formatReconciliationOrder = payloadData => {
	if(!payloadData) return [];

	return payloadData.map(item => {
		// let reconKey = item.type === 'PRODUCT' ? 'sku' : 'catalogue';
		// // let reconValue = item.type === 'PRODUCT' ? item['sku'] : item['catalogue'];
		// return ({[reconKey]: reconValue, reconciled: item.reconciled.toString()})
		return ({id: item.id, reconciled: item.reconciled.toString()});
	});
}

export const formatBillingInvoice = payloadData => {
	const {quote: data} = payloadData;
	let newArray = [['Name', 'Description', 'Amount']];
	for(const eachItem in data) {
		let entry = [`${data[eachItem]['name']}`, `${data[eachItem]['type']}`, `${data[eachItem].currency} ${formatAmountToString(data[eachItem]['amount'])}`];
		newArray.push(entry);
	}
	return newArray;
};

export const formatBusinessInvoiceRate = (data, currency, type) => {
	let newArray;

	if(type && type === "EXTENSIVE") {
		newArray = [['Date - Trip Scheduled', 'Waybill Number', 'Stops', `VAT ${currency ? `(${currency})` : ""}`, `Amount ${currency ? `(${currency})` : ""}`]]
		for(let eachItem of data["rates"]) newArray.push([
			eachItem.date,
			eachItem.waybill ? eachItem.waybill : " ",
			eachItem.stops ? eachItem.stops : "-",
			eachItem.vat ? formatAmountToString(eachItem.vat) : "- ",
			eachItem.vat ? formatAmountToString(eachItem.charge) : "-"
		]);
	} else {
		newArray = [['Date - Trip Scheduled', 'Number of trips', 'Amount']]
		for(let eachItem of data["rates"]) newArray.push([eachItem.date, eachItem.trips, formatAmountToString(eachItem.amount)]);
	}
	return newArray;
};

export const formatTripDetails = (trip) => {
	let newArray = [], totalExtrasCharge = 0;

	trip.invoice_type = trip.reference ? "RECEIPT" : "INVOICE";
	if(!trip.reference) trip.reference = "NO REFERENCE";
	if(!trip.user) trip.user = {first_name: '', last_name: ''};
	let currency = (!trip.quote.currency) ? "NGN" : trip.quote.currency;

	if(!trip["business_tripset"]) {
		for(const eachItem in trip["extras"]) {
			if(trip["extras"][eachItem] !== 0) {
				if(eachItem in trip.quote) {
					let entryKey = toTitleCase(eachItem);
					let eachEntryVal = [`${currency} ${formatAmountToString(Number(trip.quote[eachItem]))}`];
					let entry = [entryKey, eachEntryVal];
					newArray.push(entry);
					totalExtrasCharge += Number(trip.quote[eachItem]);
				}
			}
		}

		let insuranceEntry = [["Insurance"], [`${currency} ${formatAmountToString(Number(trip.quote["estimated_insurance"]))}`]];
		newArray.push(insuranceEntry);
	}


	let tripKey = [`Trip to destination (${trip.category_size.tonnage} tonne ${trip.category_size.category})`];
	let tripAmount = Number(trip.quote["total_charge"]) - (Number(totalExtrasCharge) + Number(trip.quote["vat_value"]));
	if(trip.quote["estimated_insurance"]) tripAmount -= Number(trip.quote["estimated_insurance"]);

	let tripValue = [`${currency} ${formatAmountToString(tripAmount)}`];
	let tripEntry = [tripKey, tripValue];
	newArray.push(tripEntry);

	let vatEntry = [["VAT"], [`${currency} ${formatAmountToString(Number(trip.quote["vat_value"]))}`]];
	newArray.push(vatEntry);

	if(trip.quote['discount_amount']) {
		let discountEntry = [["Discount"], [`- ${currency} ${formatAmountToString(Number(trip.quote['discount_amount']))}`]];
		newArray.push(discountEntry);
	}

	return newArray;
}

export const formatIMSTripDetails = (trip, picklist) => {
	let newArray = [], stopOversArray = [], sumTotalsArray = [],
		totalReturned = [], totalReconciled = [], totalValuation = [], totalPrice = [], totalQuantity = [];

	trip.invoice_type = trip.reference ? "RECEIPT" : "INVOICE";
	if(!trip.reference) trip.reference = "NO REFERENCE";
	if(!trip.user) trip.user = {first_name: '', last_name: ''};
	let currency = (!trip.quote.currency) ? "NGN" : trip.quote.currency;

	if(picklist && picklist.length > 0) {
		let picklistHeader = [
			[
				{text: 'Delivery ID', style: 'tableMainHeader', alignment: 'center'},
				{text: 'Store', style: 'tableMainHeader', alignment: 'center'},
				{text: 'Breakdown', style: 'tableMainHeader', colSpan: 6, alignment: 'center'},
				{},
				{},
				{},
				{},
				{},
			],
			[
				{},
				{},
				{text: 'Product', style: 'tableHeader', alignment: 'center'},
				{text: 'Unit Price', style: 'tableHeader', alignment: 'center'},
				{text: 'Quantity', style: 'tableHeader', alignment: 'center'},
				{text: 'Total Price', style: 'tableHeader', alignment: 'center'},
				{text: 'Reconciled', style: 'tableHeader', alignment: 'center'},
				{text: 'Returned', style: 'tableHeader', alignment: 'center'},
			]
		];

		for(let eachItem of picklist) {
			let store = (eachItem['merchant'] && eachItem["merchant"]["extras"] && eachItem["merchant"]["extras"]["name"])
				? eachItem["merchant"]["extras"]["name"] : "";
			for(let productItem of eachItem['products']) {
				let id = productItem['order_ref'] ?? "";
				let product = productItem['name'] ? productItem['name'] : productItem['sku'] ? productItem['sku'] : '-';
				let price = productItem['price'] ?? '0';
				let quantity = productItem['quantity'] ?? '0';
				let valuation = productItem['valuation'] ?? '0';
				let reconciled = productItem['reconciled'] ?? '0';
				let returned = productItem['returned'] ?? '0';
				let entry = [
					id,
					store,
					product,
					`${eachItem['currency']} ${formatAmountToString(price)}`,
					formatNumber(quantity),
					`${eachItem['currency']} ${formatAmountToString(valuation)}`,
					formatNumber(reconciled),
					formatNumber(returned)
				];

				stopOversArray.push(entry);
				totalPrice = [...totalPrice, price];
				totalReturned = [...totalReturned, returned];
				totalQuantity = [...totalQuantity, quantity];
				totalReconciled = [...totalReconciled, reconciled];
			}

			totalValuation = [...totalValuation, eachItem['valuation']];
		}

		let sumTotalPrice = totalPrice.reduce((accumulator, value) => Number(accumulator) + Number(value), 0);
		let sumTotalQuantity = totalQuantity.reduce((accumulator, value) => Number(accumulator) + Number(value), 0);
		let sumTotalValuation = totalValuation.reduce((accumulator, value) => Number(accumulator) + Number(value), 0);
		let sumTotalReturned = totalReturned.reduce((accumulator, value) => Number(accumulator) + Number(value), 0);
		let sumTotalReconciled = totalReconciled.reduce((accumulator, value) => Number(accumulator) + Number(value), 0);

		sumTotalsArray = [
			{colSpan: 3, text: 'TOTAL', style: 'tableHeader', alignment: 'left'},
			{},
			{},
			{text: `${currency} ${formatAmountToString(sumTotalPrice)}`, style: 'tableHeader', alignment: 'center'},
			{text: formatNumber(sumTotalQuantity), style: 'tableHeader', alignment: 'center'},
			{text: `${currency} ${formatAmountToString(sumTotalValuation)}`, style: 'tableHeader', alignment: 'center'},
			{text: formatNumber(sumTotalReconciled), style: 'tableHeader', alignment: 'center'},
			{text: formatNumber(sumTotalReturned), style: 'tableHeader', alignment: 'center'},
		]

		stopOversArray = [...picklistHeader, ...stopOversArray, sumTotalsArray];
	}

	let tripKey = [`Trip to destination (${trip.category_size.tonnage} tonne ${trip.category_size.category})`];
	let tripAmount = Number(trip.quote["total_charge"]) - Number(trip.quote["vat_value"]);
	let tripValue = [`${currency} ${formatAmountToString(tripAmount)}`];
	let tripEntry = [tripKey, tripValue];
	newArray.push(tripEntry);

	let vatEntry = [["VAT"], [`${currency} ${formatAmountToString(Number(trip.quote["vat_value"]))}`]];
	newArray.push(vatEntry);

	if(trip.quote['discount_amount']) {
		let discountEntry = [["Discount"], [`- ${currency} ${formatAmountToString(Number(trip.quote['discount_amount']))}`]];
		newArray.push(discountEntry);
	}
	return {quoteArray: newArray, stopOversArray};
}

export const formatWebsite = website => {
	if(/(http(s?)):\/\//i.test(website)) return website;
	else return `https://${website}`;
}

export const formatSupportedCountries = payloadData => {
	return payloadData.map(item => ({...item, label: item.name, value: item.country_code}));
};

export const formatServices = payloadData => {
	return payloadData.map(item => ({...item, label: item.name, value: item.reference}));
};

export const formatFleetCategories = payloadData => {
	return payloadData.map(item => ({...item, label: `${item.category} - ${item.tonnage} tonne`, value: item.tonnage}));
};

export const formatFleet = payloadData => {
	return payloadData.map(item => ({
		...item,
		label: `${item.dynamic_fields['registration_number'].toUpperCase()} - ${item.category_size.category.toUpperCase()} (${item.category_size.tonnage} tonne)`,
		value: item.id
	}));
};

export const formatRoles = payloadData => {
	return payloadData.map(item => ({...item, label: item.name, value: item.id}));
};

export const formatPermissions = payloadData => {
	let newObj = {};
	for(let permissionsEnumKey in PermissionsEnum) {
		const ifExist = payloadData.find(item => item.slug === PermissionsEnum[permissionsEnumKey]);
		if(ifExist) newObj[PermissionsEnum[permissionsEnumKey]] = true;
	}
	return newObj
};

export const formatGooglePredictions = payloadData => {
	return payloadData.map(item => ({label: item.name, value: item['place_id'], location: {address: item.name}}))
};

export const customStringGenerator = (characters, size) => {
	let result = "", charactersLength = characters.length;
	for(let i = 0; i < size; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength));
	return result;
};

export const passwordGenerator = () => {
	let suffix = customStringGenerator('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ@#$!%^&+=;', 8);
	return `Aa1;${suffix}`;
};

export const fileToBase64 = event => {
	let files;
	return new Promise(resolve => {
		if(event.dataTransfer) files = event.dataTransfer.files;
		else if(event.target) files = event.target.files;
		const reader = new FileReader();
		reader.onload = () => resolve(reader['result']);
		reader.readAsDataURL(files[0]);
	});
};

export const processSheet = file => {
	const reader = new FileReader();
	return new Promise(resolve => {
		reader.onload = event => {
			const wb = read(event.target.result);
			const sheets = wb.SheetNames;
			if(sheets.length) resolve(utils.sheet_to_json(wb.Sheets[sheets[0]]))
		}
		reader.readAsArrayBuffer(file);
	});
}

export const getBase64ImageFromURL = (url) => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.setAttribute("crossOrigin", "anonymous");
		img.onload = () => {
			const canvas = document.createElement("canvas");
			canvas.width = img.width;
			canvas.height = img.height;
			const ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0);
			const dataURL = canvas.toDataURL("image/png");
			resolve(dataURL);
		};
		img.onerror = (error) => reject(error);
		img.src = url;
	});
};

export const convertBusinessInvoiceToCSV = (data, currency, type) => {
	let jsonKeys, compiledData;

	if(type && type === "EXTENSIVE") {
		compiledData = [['Date - Trip Scheduled', 'Day', 'Waybill Number', 'Stops', `VAT ${currency ? `(${currency})` : ""}`, `Amount ${currency ? `(${currency})` : ""}`]]
		jsonKeys = ['DATE', 'DAY', 'WAYBILL', 'STOPS', 'VAT', 'CHARGE'];
	} else {
		compiledData = [['Date - Trip Scheduled', 'Day', 'Number of Trips', `Amount ${currency ? `(${currency})` : ""}`]]
		jsonKeys = ["DATE", "DAY", "TRIPS", "AMOUNT"];
	}

	let json2CSV = '\n' + compiledData.join(',') + '\n';
	for(let i = 0; i < data["rates"].length; i++) {
		let row = '';
		for(const key of jsonKeys) {
			if(row !== '') row += ','
			row += data["rates"][i][key.toLowerCase()];
		}
		json2CSV += row + '\n';
	}

	return [
		'\n',
		`BUSINESS: ${data['name']}\n`,
		`BUSINESS REFERENCE: ${data['reference']}\n`,
		`DURATION: ${data['period']}\n`,
		`TOTAL NUMBER OF TRIPS: ${data['total_trips']}\n`,
		`TOTAL NUMBER OF TRIPS: ${data['total_trips']}\n`,
		`TOTAL NUMBER OF TRIPS: ${data['total_trips']}\n`,
		`VALUE ADDED TAX: ${data['vat']}\n`,
		`TOTAL COST OF TRIPS: ${data['total']}\n`,
		'\n', json2CSV
	];
};

export const flattenObject = (obj) => {
	let result = {};

	for(const key in obj) {

		if(!obj[key]) result[key] = "_"
		else if(typeof obj[key] === "object" && !Array.isArray(obj[key])) {
			let subFlatObject = flattenObject(obj[key]);
			for(const subkey in subFlatObject) {
				if(!subFlatObject[subkey]) result[key + "_" + subkey] = "_"
				else result[key + "_" + subkey] = subFlatObject[subkey];
			}
		} else {
			result[key] = obj[key];
		}

	}
	return result;
};

export const convertJSONtoCSV = async(payload) => {
	/*
	const replacer = (key, value) => value === null ? '' : value;
		let header = Object.keys(arr[0]);
		let csv = arr.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
		header = Object.keys(header).map(key => toTitleCase(header[key]).toUpperCase())
		csv.unshift(header.join(','));
		return csv.join('\r\n'); */
	let data = "", header;
	for(let i = 0; i < payload.length; i++) {
		let result = flattenObject(payload[i]);
		let row = '';

		for(const index in result) {
			if(row !== '') row += ',';
			row += `"${result[index].toString()}"`;
		}

		if(i === 0) header = Object.keys(result);
		data += `${row}\r\n`;
	}
	header = Object.keys(header).map(key => toTitleCase(header[key]).toUpperCase());
	return `${header}\r\n${data}`;
};


export const exportData = (data, fileName, type) => {
	const blob = new Blob([data], {type});
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = fileName;
	a.click();
	window.URL.revokeObjectURL(url);
}

export const classHandler = size => {
	let gridName = {card: "col-3", grid: "col-xl-12 col-lg-12 col-sm-12"};
	if(size === 1) gridName = {card: "col-md-6", grid: "col-xl-6 col-lg-6 col-md-6 col-sm-12"};
	else if(size === 2) gridName = {card: "col-md-9", grid: "col-xl-4 col-lg-4 col-md-4 col-sm-12"};
	else if(size === 3) gridName = {card: "col-md-12", grid: "col-xl-3 col-lg-3 col-md-3 col-sm-12"};
	else if(size === 4) gridName = {card: "col-md-12", grid: "col-xl-3 col-lg-3 col-md-3 col-sm-12"};
	else if(size >= 4) gridName = {card: "col-md-12", grid: "col-xl-3 col-lg-3 col-md-4 col-sm-12"};
	return gridName;
};

export const gridHandler = size => {
	let gridName = "col-xl-3 col-lg-3 col-md-3 col-sm-3";
	if(size === 1) gridName = "col-xl-3 col-lg-3 col-md-3 col-sm-3";
	else if(size === 2) gridName = "col-xl-3 col-lg-3 col-md-3 col-sm-12";
	else if(size === 3) gridName = "col-xl-3 col-lg-3 col-md-3 col-sm-12";
	else if(size >= 4) gridName = "col-xl-3 col-lg-3 col-md-3 col-sm-3";
	return gridName;
};

export const formatAmountToString = data => {
	const format = !data ? "0.00" : data;
	if(format.toString() === "0.00") return format.toLocaleString();
	else if(format.toString().includes(",")) {
		const newFormat = format.toString().replace(/,/g, "");
		const formattedNumber = parseFloat(newFormat);
		return formattedNumber.toLocaleString();
	} else if(format.toString().includes(".")) {
		const formatValue = truncateDecimalValue(format);
		return formatValue.toLocaleString();
	} else return `${format.toLocaleString()}`;
};

export const truncateDecimalValue = value => {
	value = value.toString();
	const numberValue = value.split(".")[0];
	const suffixedNumber = value.split(".")[1].substring(0, 2);
	const decimalCount = value.split(".")[1].length;
	const prefixedNumber = Number(numberValue).toLocaleString();
	if(decimalCount > 2) value = `${prefixedNumber}.${suffixedNumber}`;
	else if(decimalCount < 2) value = `${prefixedNumber}.${suffixedNumber}0`;
	else value = suffixedNumber === "00" ? prefixedNumber : `${prefixedNumber}.${suffixedNumber}`;
	return value;
};

export const getBusinessExtensiveInsight = data => {
	let businesses = [], businessMap = [];
	if(!data) return [];

	for(let eachMonth of data) {
		for(let business of eachMonth.business) {
			if(businessMap.indexOf(business.business) === -1) {
				businessMap.push(business.business);
				businesses.push({reference: business.business, name: business.name});
			}
		}
	}
	return businesses;
};

export const extractBusinessDataInsight = (data, reference) => {
	let businessData = [];
	if(!data) return [];

	for(let each of data) {
		let businessInMonth = each.business.find(eachMap => eachMap.business === reference);
		let monthData = {month: each.month}
		monthData.trips = businessInMonth && businessInMonth.count ? businessInMonth.count : 0;
		monthData.gross = businessInMonth && businessInMonth.gross ? formatAmountToString(businessInMonth.gross) : 0;
		monthData.net = businessInMonth && businessInMonth.net ? formatAmountToString(businessInMonth.net) : 0;
		businessData.push(monthData);
	}
	return businessData;
};


export const getTripTypeExtensiveInsight = data => {
	let tripTypes = [], tripMap = [];
	if(!data) return [];

	for(let item of data) {
		for(let tripType of item.trips) {
			if(tripMap.indexOf(tripType.key) === -1) {
				tripMap.push(tripType.key);
				tripTypes.push({
					key: tripType.key,
					customized_name: tripType.customized_name,
					system_name: tripType.system_name
				});
			}
		}
	}
	return tripTypes;
};
